"use client"

import Link from "next/link"
import voxieZero from "public/images/voxie_zero.png"
import Image from "next/image"
import Tooltip from "@alwaysgeeky-games/shared-ui-components/Tooltip"
import { useLogout } from "@account-kit/react"
import { useConnectedUserProfile } from "utils/hooks/useConnectedUserProfile"
import { DisconnectIcon } from "components/Icons/DisconnectIcon/DisconnectIcon"
import { useCognitoContext } from "components/Providers/CognitoProvider"
import { useRouter } from "next/navigation"

export const ProfileLink = () => {
  const {
    selectors: { userProfile },
  } = useConnectedUserProfile()
  const { handleSignOut } = useCognitoContext()
  const router = useRouter()
  const { logout } = useLogout({
    onError: error => console.error(error),
  })
  const handleLogOut = e => {
    e.preventDefault()
    handleSignOut()
    logout()
    router.push("/")
  }

  if (!userProfile) {
    return null
  }

  return (
    <div className="container mx-auto flex flex-row items-center justify-end relative z-10 gap-4">
      <Link
        href={"/account/"}
        data-test="profile-link"
        className="group inline-flex items-center h-10 bg-base-200 cursor-pointer rounded-full p-1 text-black hover:bg-yellowDark transition-all duration-100 ease-in-out"
      >
        <div className="flex flex-row items-center gap-2">
          <div className="rounded-full w-8 border-2 bg-yellow border-white shadow-md group-hover:translate-x-1 transition-all duration-100 ease-in-out">
            <Image src={voxieZero} width={30} height={30} alt="Profile Image" />
          </div>
          <div className="text-base-content font-poppins font-semibold pr-2">
            {!userProfile.isTempUsername ? userProfile.username : "Profile"}
          </div>
        </div>
      </Link>
      <Tooltip content="Disconnect">
        <button
          onClick={handleLogOut}
          className="flex items-center py-2 px-3 h-full rounded-full text-black hover:bg-yellowDark transition-all duration-100 ease-in-out bg-yellow"
        >
          <DisconnectIcon className="w-6 h-6 fill-white" color="currentFill" />
        </button>
      </Tooltip>
    </div>
  )
}
